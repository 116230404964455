import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
//import { ResponseContentType} from '@angular/http';

import { connectPayload } from '../models/connectPayload';
import { booResponse } from '../models/booResponse';
import { childminderResponse } from '../models/childminderResponse';
import { childDetailResponse } from '../models/childDetailResponse';
import { childTmlResponse } from '../models/childTmlResponse';
import { childPDFBillResponse } from '../models/childPDFBillResponse';
import { childPresencesResponse } from '../models/childPresencesResponse';
import { createcheckoutsessionResponse } from '../models/createcheckoutsessionResponse';
import { deleteChildResponse } from '../models/deleteChildResponse';
import { archiveChildResponse } from '../models/archiveChildResponse';
import { archivechildminderResponse } from '../models/archivechildminderResponse';
import { addChildData } from '../models/addChildData';
import { customerPortalResponse } from '../models/customerPortalResponse';

import { formatDate } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  // Define API
  apiURL = 'https://app.boo-solution.com/BooWebServices/BooAdminWS';
  apiURLmobile = 'https://app.boo-solution.com/BooWebServices/BooNounouWS';
  userid = sessionStorage.getItem('UserId');
  currentChildId = sessionStorage.getItem('currentChildId');
  billingDate = sessionStorage.getItem('billingDate');
  billingChildId = sessionStorage.getItem('billingChildId');
  tmlDate = sessionStorage.getItem('tmlDate');
  priceId: string;
  priceId1 = 'price_1IkZ7sEvBaP6POj1SPxby7fM';
  priceId2 = 'price_1IkZAiEvBaP6POj1GiO8ecdm';
  priceId3 = 'price_1IkZCsEvBaP6POj1zP95ANhH';
  priceId4 = 'price_1IkZEdEvBaP6POj1yObZKSxv';

  constructor(private http: HttpClient) { }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  }

  //connect
  putConnect(connectPayload): Observable<booResponse> {
   return this.http.put<booResponse>(this.apiURL + '/connect', JSON.stringify(connectPayload), this.httpOptions)
   .pipe(
     retry(1),
     catchError(this.handleError)
   )
  }

  //postchild
  postChild(): Observable<addChildData> {
   return this.http.post<addChildData>(this.apiURL + '/postChild/', this.httpOptions)
   //.pipe(
     //retry(1),
     //catchError(this.handleError)
   //)
  }

  //get user infos
  getChildsForChildminder(): Observable<childminderResponse> {
   return this.http.get<childminderResponse>(this.apiURL + '/getChildsForChildminder/' + sessionStorage.getItem('UserId') + '/' + formatDate(new Date(), 'yyyy-MM-ddTHH:mm:ssZ', 'en'), this.httpOptions)
   //window.alert(sessionStorage.getItem('UserId'));
   //.pipe(
  //   retry(1),
  //   catchError(this.handleError)
  // )
  }

  getArchivedChildsForChildminder(): Observable<archivechildminderResponse> {
   return this.http.get<archivechildminderResponse>(this.apiURL + '/getArchivedChildsForChildminder/' + sessionStorage.getItem('UserId'), this.httpOptions)
   //.pipe(
  //   retry(1),
  //   catchError(this.handleError)
  // )
  }

  getChilddetail(): Observable<childDetailResponse> {
   return this.http.get<childDetailResponse>(this.apiURL + '/getInfosEnfant/' + sessionStorage.getItem('currentChildId') + '/' + formatDate(new Date(), 'yyyy-MM-ddTHH:mm:ssZ', 'en'), this.httpOptions)
   //window.alert(sessionStorage.getItem('currentChildId'));

   //.pipe(
  //   retry(1),
  //   catchError(this.handleError)
  // )
  }

  getChildtmldetail(): Observable<childTmlResponse> {
   return this.http.get<childTmlResponse>(this.apiURL + '/getInfosEnfant/' + sessionStorage.getItem('currentChildId') + '/' + (sessionStorage.getItem('tmlDate')), this.httpOptions)

   .pipe(
    retry(1),
     catchError(this.handleError)
   )
  }



  postCreateCheckoutSession(idpack):Observable<createcheckoutsessionResponse> {

       if (idpack == "priceId1") {
         this.priceId = this.priceId1;
       }else if (idpack == "priceId2") {
         this.priceId = this.priceId2;
       }else if (idpack == "priceId3") {
         this.priceId = this.priceId3;
       }else if (idpack == "priceId4") {
         this.priceId = this.priceId4;
       }
       const body = { priceId: this.priceId, childminderId: sessionStorage.getItem('UserId'),successUrl: `${window.location.href}/success`, cancelUrl: `${window.location.href}/failure` };
   return this.http.post<createcheckoutsessionResponse>(this.apiURL + '/createCheckoutSession', body, this.httpOptions)

 }

  postDeleteChild():Observable<deleteChildResponse> {
    return this.http.delete<deleteChildResponse>(this.apiURL + '/deleteChild/' + sessionStorage.getItem('currentEditChildId'), this.httpOptions)

  }
  postdeleteArchiveChild():Observable<deleteChildResponse> {
    return this.http.delete<deleteChildResponse>(this.apiURL + '/deleteArchiveChild/' + sessionStorage.getItem('currentEditChildId'), this.httpOptions)

  }
  postArchiveChild():Observable<archiveChildResponse> {
    return this.http.delete<archiveChildResponse>(this.apiURL + '/archiveChild/' + sessionStorage.getItem('currentEditChildId'), this.httpOptions)

  }

  getChildrenPresences():Observable<childPresencesResponse> {
   return this.http.get<childPresencesResponse>(this.apiURL + '/getChildrenPlanningByDay/' + sessionStorage.getItem('UserId') + '/2020/02', this.httpOptions)
   //idenfant/annee/mois
  }

  /*getChildPDFBill():Observable<childPDFBillResponse> {
   return this.http.get<childPDFBillResponse>(this.apiURL + '/getChildBill/0030675d-a8b3-4262-a949-02b3e9bfc9ac/' + sessionStorage.getItem('billingDate'), this.httpOptions)
   //return this.http.get<childPDFBillResponse>(this.apiURL + '/getChildBill/' + sessionStorage.getItem('billingChildId') + '/' + sessionStorage.getItem('billingDate'), this.httpOptions)

   //idenfant/annee/mois
 }*/

  /*getBillFile(){
    const httpOptions2 = {

      headers: new HttpHeaders({
        'responseType': 'blob',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'observe' : 'response'
      })
    };
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf, ');
		return this.http.get(this.apiURL + '/getChildPDFBillFile/' + sessionStorage.getItem('billingChildId') + '/' + sessionStorage.getItem('billingDate'), { headers: headers, responseType: 'blob' });
  }*/

  getChildPDFBill():Observable<childPDFBillResponse> {
   return this.http.get<childPDFBillResponse>(this.apiURL + '/getChildBill/' + sessionStorage.getItem('billingChildId') + '/' + sessionStorage.getItem('billingDate'), this.httpOptions)
   //idenfant/annee/mois
  }

   getBillFile(){
     const httpOptions2 = {

       headers: new HttpHeaders({
         'responseType': 'blob',
         'Content-Type': 'application/json',
         'Accept': 'application/json',
         'observe' : 'response'
       })
     };
     let headers = new HttpHeaders();
     headers = headers.set('Accept', 'application/pdf, */*');
 		return this.http.get(this.apiURL + '/getChildPDFBillFile/' + sessionStorage.getItem('billingChildId') + '/' + sessionStorage.getItem('billingDate'), { headers: headers, responseType: 'blob' });
    }


  // Error handling
 handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
 }

 postCustomerPortal():Observable<customerPortalResponse> {
    const body = { childminderId: sessionStorage.getItem('UserId'),returnUrl: `${window.location.href}/abonnement`};
    return this.http.post<customerPortalResponse>(this.apiURL + '/customerPortal', body, this.httpOptions)
  }

}
